import { useEffect, useState, useRef, useCallback, Fragment } from 'react'
import ReactMapGL from 'react-map-gl'
import DeckGL from '@deck.gl/react'
import { type PickingInfo, type MapViewState, type ViewStateChangeParameters } from '@deck.gl/core'
import { type MjolnirEvent } from 'mjolnir.js'
import { useInterval } from 'react-use'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { VEHICLE_ICON_MAPPING } from 'src/components/vmap/data/vehicle-icon-mapping'
import ScooterPins from 'src/components/vmap/data/vehicle_state_icons.png'
import IconClusterLayer from 'src/components/vmap/icon-cluster-layer/IconClusterLayer'
import { updateVehicleMap, selectVmapCurrentZone, selectVmapInterval, selectIsVmapLoading } from 'src/redux/vmap'
import { selectZoneOptions } from 'src/redux/zone'
import { clearVehicleBulkList, setVehicleBulkList, selectVehicleBulkList } from 'src/redux/scooterBulk'
import { prettifyVehicleAvailability } from 'src/utils/vehicles/vehiclesUtils'
import { Wrapper as ToolTipWrapper, AreaTooltipContent } from 'src/components/parts/map/tooltip/tooltipUtils'
import styled from 'styled-components'
import { Link } from 'react-router'
import { handleBulkVehicles } from 'src/components/vmap/bulk-list/types'
import { resetAreas, selectZoneAreasByType } from 'src/redux/zoneAreas'
import { selectToggledAreaTypes, toggleAreaType } from 'src/redux/mapSettings'
import { createAreasLayers } from 'src/components/parts/map/layers/layersUtils'
import { vehicleToBulkListVehicle } from 'src/utils/scooterBulk'
import { type Vehicle } from 'src/api/fm/vehicles/vehicles.model'
import { Button } from 'src/ui-kit/button/Button'
import { DECKGL_SIZE_SCALE } from 'src/constants/maps'
import { type Area, type AreaType } from 'src/api/fm/zones/zones.model'
import { ToggleMapViewButton, MapView } from 'src/pages/vmap/mapActionButtons/ToggleMapViewButton'
import { ToggleVisibleAreasButton } from 'src/pages/vmap/mapActionButtons/ToggleVisibleAreasButton'
import { HoveredVehiclesTooltip } from 'src/components/parts/map/tooltip/HoveredVehiclesTooltip'
import Geocoder from 'src/components/geocoder/Geocoder'
import { getGeoJsonCenter } from 'src/components/app/utils/geojson/geoJsonUtils'
import { getTransitionDuration } from 'src/utils/map/mapUtils'

export const INITIAL_VIEW_STATE: MapViewState = {
    latitude: 54.526,
    longitude: 15.2551,
    zoom: 3,
    maxZoom: 20,
    bearing: 0,
    pitch: 0,
}

interface Props {
    vehiclesOnMap: Vehicle[]
}

interface HoveredVehicles {
    vehicles: Vehicle[]
    expanded: boolean
    x: number
    y: number
}

interface HoveredArea {
    area: Area
    x: number
    y: number
}

export const Map = (props: Props) => {
    const { vehiclesOnMap } = props
    const [mapView, setMapView] = useState(MapView.Streets)
    const showIconFiltersRef = useRef(false)
    const [hoveredVehicles, setHoveredVehicles] = useState<HoveredVehicles | null>(null)
    const [hoveredArea, setHoveredArea] = useState<HoveredArea | null>(null)
    const [viewState, setViewState] = useState({
        viewState: INITIAL_VIEW_STATE,
    })
    const [cursor, setCursor] = useState('grab')

    const mapInterval = useAppSelector(selectVmapInterval)
    const bulkList = useAppSelector(selectVehicleBulkList)
    const currentZone = useAppSelector(selectVmapCurrentZone)
    const zoneOptions = useAppSelector(selectZoneOptions)
    const areasByType = useAppSelector(selectZoneAreasByType)
    const shownAreaTypes = useAppSelector(selectToggledAreaTypes)
    const isVmapLoading = useAppSelector(selectIsVmapLoading)

    const dispatch = useAppDispatch()

    useInterval(() => {
        // Skip updating map if document is hidden (i.e. browser tab not in focus) OR map is already loading (i.e. API request already in progress)
        if (document.hidden || isVmapLoading) {
            return
        }

        dispatch(updateVehicleMap())
    }, parseInt(mapInterval))

    useEffect(() => {
        if (currentZone) {
            const zoneCenter = getGeoJsonCenter(currentZone.boundaries)

            setViewState(prev => ({
                viewState: {
                    ...prev.viewState,
                    latitude: zoneCenter.lat,
                    longitude: zoneCenter.lng,
                    zoom: 10,
                },
            }))
        }
    }, [currentZone])

    useEffect(() => {
        return () => {
            dispatch(clearVehicleBulkList())
            dispatch(resetAreas())
        }
    }, [dispatch])

    const handleSetBulkListVehicles = (vehicles: Vehicle[]) => {
        const fmtNewVehicles = vehicles.map(v => vehicleToBulkListVehicle(v, zoneOptions))
        const prevBulkList = bulkList || []
        const newBulkList = handleBulkVehicles(prevBulkList, fmtNewVehicles)

        dispatch(setVehicleBulkList(newBulkList))
    }

    const toggleShownAreaType = (areaType: AreaType) => {
        dispatch(toggleAreaType(areaType))
    }

    const _onHover = (info: PickingInfo<Vehicle>) => {
        // HACK: Use a ref here since changes to _onHover function does not change
        // the onHover function inside areasLayers
        if (showIconFiltersRef.current) {
            return
        }

        setCursor(info.picked ? 'pointer' : 'grab')

        if (hoveredVehicles?.expanded) {
            return
        }

        const { x, y, object } = info
        const z = info.layer!.state.z

        if (object) {
            // @ts-expect-error zoomLevels gets added to each vehicle in IconClusterLayer. Needs bigger refactoring to make types correct
            if (object.zoomLevels[z]) {
                // Multiple hovered vehicles
                // @ts-expect-error fix this
                const vehicles = object.zoomLevels[z].points

                setHoveredVehicles({ vehicles, x, y, expanded: false })
            } else {
                // Single hovered vehicle
                const vehicles = [object]

                setHoveredVehicles({ vehicles, x, y, expanded: false })
            }
        } else {
            setHoveredVehicles(null)
        }
    }

    const onHoverArea = (info: PickingInfo<Area>) => {
        // HACK: Use a ref here since changes to _onHover function does not change
        // the onHover function inside areasLayers
        if (showIconFiltersRef.current) {
            return
        }

        setCursor(info.picked ? 'pointer' : 'grab')

        const { x, y, object } = info
        const area = object

        if (!area) {
            setHoveredArea(null)
        } else {
            setHoveredArea({ area, x, y })
        }
    }

    const onClickVehicles = (info: PickingInfo<Vehicle>, e: MjolnirEvent) => {
        const { x, y, object } = info
        const z = info.layer!.state.z

        // @ts-expect-error zoomLevels gets added to each vehicle in IconClusterLayer. Needs bigger refactoring to make types correct
        const vehicles = object?.zoomLevels[z]?.points

        if (!vehicles) {
            return
        }

        if (e.srcEvent.shiftKey === true) {
            setHoveredVehicles({ vehicles, x, y, expanded: true })
        } else {
            handleSetBulkListVehicles(vehicles)
        }
    }

    const _closePopup = () => {
        setHoveredVehicles(null)
    }

    const _renderLayers = () => {
        let vehicleLayer

        if (vehiclesOnMap && vehiclesOnMap.length) {
            vehicleLayer = new IconClusterLayer({
                id: 'icon-cluster',
                data: vehiclesOnMap,
                pickable: true,
                wrapLongitude: true,
                getPosition: d => [d.location[1], d.location[0]],
                iconAtlas: ScooterPins,
                iconMapping: VEHICLE_ICON_MAPPING,
                onHover: _onHover,
                onClick: onClickVehicles,
                sizeScale: DECKGL_SIZE_SCALE,
                isSleeping: currentZone?.isSleeping ?? false,
                bulkList: bulkList ?? [],
                hoveredItems: hoveredVehicles?.vehicles ?? [],
                opacity: 0.8,
            })
        }

        const areasLayers = createAreasLayers(areasByType, shownAreaTypes, onHoverArea)

        return [...areasLayers, vehicleLayer]
    }

    const renderHoveredVehicles = () => {
        if (!hoveredVehicles) {
            return null
        }

        const { vehicles, x, y, expanded } = hoveredVehicles

        if (expanded) {
            return (
                <ToolTipWrapper style={{ left: x, top: y }} onMouseLeave={_closePopup}>
                    <Button dataTestId='vmap-add-to-bulk' onClick={() => handleSetBulkListVehicles(vehicles)}>
                        Add to bulk
                    </Button>
                    {vehicles.length !== 1 && <p>Number of vehicles: {vehicles.length}</p>}
                    <div>
                        <hr />
                        {vehicles.map((vehicle, i) => (
                            <Fragment key={i}>
                                <ToolTipListWrapper>
                                    <p style={{ margin: '2px 0', width: '40px' }}>
                                        <Link
                                            to={`/vehicles/${vehicle.short}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {vehicle.short}
                                        </Link>
                                    </p>
                                    <p style={{ margin: '2px 0', width: '75px' }}>
                                        Battery: <strong>{vehicle.battery}</strong>
                                    </p>
                                    <p style={{ margin: '2px 7px' }}>
                                        Status: <strong>{prettifyVehicleAvailability(vehicle.availability)}</strong>
                                    </p>
                                </ToolTipListWrapper>
                                <hr />
                            </Fragment>
                        ))}
                    </div>
                </ToolTipWrapper>
            )
        }

        if (vehicles?.length > 0) {
            return <HoveredVehiclesTooltip x={x} y={y} vehicles={vehicles} />
        }

        return undefined
    }

    const renderHoveredArea = () => {
        if (!hoveredArea) {
            return null
        }

        const { x, y, area } = hoveredArea

        return (
            <ToolTipWrapper $pointerEvents='none' $overflowY='hidden' style={{ left: x, top: y }}>
                <AreaTooltipContent areaProperties={area.properties} />
            </ToolTipWrapper>
        )
    }

    const geocoderContainerRef = useRef<HTMLDivElement>(null)
    const vehicleMap = useRef(null)

    const handleGeocoderViewPortChange = useCallback(
        (newViewState: MapViewState) => {
            setViewState({
                viewState: {
                    ...newViewState,
                    transitionDuration: 1500,
                },
            })
        },
        [setViewState],
    )

    const handleViewStateChange = (params: ViewStateChangeParameters<MapViewState>) => {
        setViewState({
            viewState: { ...params.viewState, transitionDuration: getTransitionDuration(params) },
        })
    }

    return (
        <Wrapper>
            <GeocoderContainer ref={geocoderContainerRef} />
            <DeckGL
                layers={_renderLayers()}
                viewState={viewState.viewState}
                controller={true}
                getCursor={() => cursor}
                onViewStateChange={params => handleViewStateChange(params as ViewStateChangeParameters<MapViewState>)}
            >
                <ReactMapGL
                    ref={vehicleMap}
                    mapStyle={`mapbox://styles/mapbox/${mapView}-v9`}
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
                    reuseMaps={true}
                />
                <Geocoder
                    placeholder={'Search for address'}
                    mapRef={vehicleMap}
                    containerRef={geocoderContainerRef}
                    onViewportChange={handleGeocoderViewPortChange}
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
                    marker={false}
                    collapsed
                />

                {renderHoveredVehicles()}
                {renderHoveredArea()}
                <ToggleMapViewButton currentMapView={mapView} setMapView={setMapView} />
                <ToggleVisibleAreasButton
                    onOpenSelectAreas={() => {
                        showIconFiltersRef.current = true
                        setHoveredArea(null)
                        setHoveredVehicles(null)
                    }}
                    onCloseSelectAreas={() => (showIconFiltersRef.current = false)}
                    onToggleShownAreaType={toggleShownAreaType}
                    shownAreaTypes={shownAreaTypes}
                />
            </DeckGL>
        </Wrapper>
    )
}

const ToolTipListWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
`

const Wrapper = styled.div`
    min-height: 400px;
`

const GeocoderContainer = styled.span`
    position: absolute;
    width: 100%;
    max-width: 420px;
`
