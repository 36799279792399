import { Outlet } from 'react-router'
import BatteryContextProvider from 'src/contexts/battery/Context'

const BatteryContextLayout = () => {
    return (
        <BatteryContextProvider>
            <Outlet />
        </BatteryContextProvider>
    )
}

export default BatteryContextLayout
