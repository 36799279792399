import type { NavigateFunction } from 'react-router'
import { type RepairAccount } from 'src/api/fm/repairs/repairs.model'
import {
    REMOVE_VEHICLES_FROM_REPAIR,
    GET_REPAIR_ACCOUNT,
    RESET_CURRENT_REPAIR_VEHICLE,
    GET_REPAIR_VEHICLE,
    CREATE_VEHICLE_SPECS,
    SET_CURRENT_REPAIR_ACCOUNT,
    CREATE_REPAIR_ISSUE,
    UPDATE_REPAIR_ISSUE,
    CREATE_REPAIR_ACCOUNT,
    UPDATE_VEHICLE_SPECS,
    SEARCH_REPAIR_ACCOUNT,
    DELETE_REPAIR_ACCOUNT,
    DELETE_VEHICLE_IMG,
    SET_CURRENT_REPAIR_VEHICLE,
    SET_SPARE_PARTS,
    SET_REPAIR_ISSUES,
    GET_REPAIR_ISSUES,
    DELETE_REPAIR_ISSUE,
    type RepairIssue,
    type CreateRepairAccount,
    type GetRepairAccount,
    type SearchRepairAccount,
    type DeleteRepairAccount,
    type GetRepairVehicle,
    type CreateVehicleSpecs,
    type UpdateVehicleSpecs,
    type DeleteVehicleImg,
    type CreateRepairIssue,
    type UpdateRepairIssue,
    type DeleteRepairIssue,
    type RemoveFromRepairs,
    type GetRepairIssues,
    type ResetCurrentRepairVehicle,
    type SetCurrentRepairAccount,
    type SetCurrentRepairVehicle,
    type SetSpareParts,
    type SetRepairIssues,
} from 'src/redux/repair/repair.types'
import { type VehicleModel } from 'src/api/fm/vehicles/vehicles.model'
import { type SparePart } from 'src/api/fm/warehouseInventory/warehouseInventory.model'

export const removeFromRepairs = (scooterIds: string[]): RemoveFromRepairs => {
    return {
        type: REMOVE_VEHICLES_FROM_REPAIR,
        payload: { scooterIds },
    }
}

export const resetCurrentRepairVehicle = (): ResetCurrentRepairVehicle => ({
    type: RESET_CURRENT_REPAIR_VEHICLE,
})

export const getRepairVehicle = (payload: string): GetRepairVehicle => ({
    type: GET_REPAIR_VEHICLE,
    payload,
})

export const getRepairIssues = (payload: string): GetRepairIssues => ({
    type: GET_REPAIR_ISSUES,
    payload,
})

export const createVehicleSpecs = (payload: VehicleModel): CreateVehicleSpecs => ({
    type: CREATE_VEHICLE_SPECS,
    payload,
})

export const createRepairIssue = (payload: RepairIssue): CreateRepairIssue => ({
    type: CREATE_REPAIR_ISSUE,
    payload,
})

export const updateRepairIssue = (payload: RepairIssue): UpdateRepairIssue => ({
    type: UPDATE_REPAIR_ISSUE,
    payload,
})

export const deleteRepairIssue = (vehicleModelId: string, repairIssueId: string): DeleteRepairIssue => ({
    type: DELETE_REPAIR_ISSUE,
    payload: { vehicleModelId, repairIssueId },
})

export const createRepairAccount = (email: string): CreateRepairAccount => ({
    type: CREATE_REPAIR_ACCOUNT,
    payload: email,
})

export const deleteRepairAccount = (id: string, navigateFn: NavigateFunction): DeleteRepairAccount => ({
    type: DELETE_REPAIR_ACCOUNT,
    payload: { id, navigateFn },
})

export const getRepairAccount = (id: string): GetRepairAccount => ({
    type: GET_REPAIR_ACCOUNT,
    payload: id,
})

export const setCurrentRepairAccount = (repairAccount: RepairAccount | null): SetCurrentRepairAccount => ({
    type: SET_CURRENT_REPAIR_ACCOUNT,
    payload: repairAccount,
})

export const searchRepairAccount = (searchTerm: string, navigateFn: NavigateFunction): SearchRepairAccount => ({
    type: SEARCH_REPAIR_ACCOUNT,
    payload: { searchTerm, navigateFn },
})

export const updateVehicleSpecs = (payload: VehicleModel): UpdateVehicleSpecs => ({
    type: UPDATE_VEHICLE_SPECS,
    payload,
})

export const deleteVehicleImg = (payload: VehicleModel): DeleteVehicleImg => ({
    type: DELETE_VEHICLE_IMG,
    payload,
})

export const setCurrentRepairVehicle = (payload: VehicleModel | null): SetCurrentRepairVehicle => ({
    type: SET_CURRENT_REPAIR_VEHICLE,
    payload,
})

export const setSpareParts = (payload: SparePart[]): SetSpareParts => ({
    type: SET_SPARE_PARTS,
    payload,
})

export const setRepairIssues = (payload: RepairIssue[]): SetRepairIssues => ({
    type: SET_REPAIR_ISSUES,
    payload,
})
