import { type NavigateFunction } from 'react-router'
import {
    GET_HUNTER,
    SET_CURRENT_HUNTER,
    RESET_CURRENT_HUNTER,
    SUBMIT_EDITED_HUNTER,
    SUBMIT_NEW_HUNTER,
    DELETE_HUNTER,
    SET_HUNTER_HISTORY,
    GET_HUNTER_HISTORY,
    RESET_HUNTER_HISTORY_FOR_HUNTER,
    GET_HUNTER_TASK_HISTORY_NEXT_PAGE,
    INCREMENT_HUNTER_TASK_HISTORY_PAGINATION,
    SET_HUNTER_TASK_HISTORY_END_OF_PAGINATION,
    SET_HUNTER_HISTORY_SORT_PARAM_FOR_HUNTER,
    GET_ALL_ORGANIZATIONS,
    SEARCH_HUNTER,
    RESET_HUNTER_PASSWORD,
    SORT_HUNTER_HISTORY_FOR_HUNTER,
    SET_ALL_ORGS,
    type Hunter,
    type SubmitNewHunter,
    type GetHunter,
    type SetCurrentHunter,
    type SubmitEditedHunter,
    type DeleteHunter,
    type SearchHunter,
    type ResetHunterPassword,
    type SetIsFetchingHunterHistory,
    SET_IS_FETCHING_HUNTER_HISTORY,
    type GetHunterHistory,
    type SetHunterHistory,
    type ResetCurrentHunter,
    type ResetHunterHistoryForHunter,
    type SetHunterHistorySortParamForHunter,
    type GetOrganizations,
    type SetAllOrgs,
    type SortHunterHistory,
    type GetHunterTaskHistoryNextPage,
    type IncrementHunterTaskHistoryPagination,
    type SetHunterTaskHistoryEndOfPagination,
} from 'src/redux/hunter/hunter.types'
import { type Organization } from 'src/redux/organization/organization.types'
import { type Task } from 'src/api/fm/tasks/tasks.model'

export const getHunter = (id: string): GetHunter => ({
    type: GET_HUNTER,
    payload: id,
})

export const setCurrentHunter = (hunter: Hunter): SetCurrentHunter => ({
    type: SET_CURRENT_HUNTER,
    payload: hunter,
})

export const resetCurrentHunter = (): ResetCurrentHunter => ({
    type: RESET_CURRENT_HUNTER,
})

export const submitEditedHunter = (hunter: Hunter): SubmitEditedHunter => ({
    type: SUBMIT_EDITED_HUNTER,
    payload: hunter,
})

export const submitNewHunter = (hunter: Omit<Hunter, 'accountId'>, navigateFn: NavigateFunction): SubmitNewHunter => ({
    type: SUBMIT_NEW_HUNTER,
    payload: { hunter, navigateFn },
})

export const deleteHunter = (id: string, navigateFn: NavigateFunction, reason: string): DeleteHunter => ({
    type: DELETE_HUNTER,
    payload: { id, navigateFn, reason },
})

export const getHunterHistory = (): GetHunterHistory => ({
    type: GET_HUNTER_HISTORY,
})

export const setHunterHistory = (data: Task[]): SetHunterHistory => ({
    type: SET_HUNTER_HISTORY,
    payload: data,
})

export const setIsFetchingHunterHistory = (isFetching: boolean): SetIsFetchingHunterHistory => {
    return {
        type: SET_IS_FETCHING_HUNTER_HISTORY,
        payload: isFetching,
    }
}

export const resetHunterHistoryForHunter = (): ResetHunterHistoryForHunter => ({
    type: RESET_HUNTER_HISTORY_FOR_HUNTER,
})

export const resetHunterPassword = (email: string): ResetHunterPassword => ({
    type: RESET_HUNTER_PASSWORD,
    payload: email,
})

export const setHunterHistorySortParamForHunter = (param: string): SetHunterHistorySortParamForHunter => ({
    type: SET_HUNTER_HISTORY_SORT_PARAM_FOR_HUNTER,
    payload: param,
})

export const getOrganizations = (): GetOrganizations => ({
    type: GET_ALL_ORGANIZATIONS,
})

export const setAllOrgs = (payload: Organization[]): SetAllOrgs => ({
    type: SET_ALL_ORGS,
    payload,
})

export const searchHunter = (searchTerm: string, navigateFn: NavigateFunction): SearchHunter => ({
    type: SEARCH_HUNTER,
    payload: { searchTerm, navigateFn },
})

export const sortHunterHistory = (payload: string): SortHunterHistory => ({
    type: SORT_HUNTER_HISTORY_FOR_HUNTER,
    payload,
})

export const getHunterTaskHistoryNextPage = (): GetHunterTaskHistoryNextPage => ({
    type: GET_HUNTER_TASK_HISTORY_NEXT_PAGE,
})

export const incrementHunterTaskHistoryPagination = (): IncrementHunterTaskHistoryPagination => ({
    type: INCREMENT_HUNTER_TASK_HISTORY_PAGINATION,
})

export const setHunterTaskHistoryEndOfPagination = (): SetHunterTaskHistoryEndOfPagination => ({
    type: SET_HUNTER_TASK_HISTORY_END_OF_PAGINATION,
})
