export const permissions = {
    accident_editor: 'accident_editor',
    accident_reason_editor: 'accident_reason_editor',
    areas_editor: 'area_editor',
    battery_editor: 'battery_editor',
    battery_shipment_editor: 'battery_shipment_editor',
    battery_threshold_editor: 'battery_threshold_editor',
    battery_provisioner: 'battery_provisioner',
    better_cloud_editor: 'bettercloud_editor',
    bulk_repairs_editor: 'repairs_bulk_editor',
    city_regs_editor: 'city_regs_editor',
    discount_editor: 'discount_editor',
    hunter_editor: 'hunter_editor',
    hunter_organizations_editor: 'hunter_organization_editor',
    hunter_parking_photo_editor: 'hunter_parking_photo_editor',
    leap_editor: 'leap_editor',
    maintenance_need_editor: 'maintenance_need_editor',
    mechanics_editor: 'mechanic_editor',
    parking_assistant_editor: 'parking_assistant_editor',
    partners_editor: 'partners_editor',
    payment_editor: 'payment_editor',
    permission_editor: 'permission_editor',
    pricing_editor: 'pricing_editor',
    promotions_editor: 'promotion_editor',
    repair_types_editor: 'repair_type_editor',
    rides_editor: 'ride_editor',
    safety_control_settings_editor: 'safety_control_settings_editor',
    tasks_bulk_editor: 'task_bulk_editor',
    tasks_editor: 'task_editor',
    tasks_priority_editor: 'task_priority_editor',
    user_discount_editor: 'user_discount_editor',
    user_ride_plan_editor: 'user_ride_plan_editor',
    users_deleter: 'user_deleter',
    users_editor: 'user_editor',
    users_viewer: 'user_viewer',
    vehicle_decomission_editor: 'vehicle_decomission_editor',
    vehicle_policy_editor: 'vehicle_policy_editor',
    vehicle_provision_editor: 'vehicle_provision_editor',
    vehicle_seller_editor: 'vehicle_seller_editor',
    vehicle_zone_editor: 'vehicle_zone_editor',
    vehicles_editor: 'vehicle_editor',
    warehouse_editor: 'warehouse_editor',
    work_area_editor: 'work_area_editor',
    zone_settings_editor: 'zone_settings_editor',
    zones_editor: 'zone_editor',
}
