import { searchRider, getRider } from 'src/api'
import type { Rider } from 'src/api/fm/users/users.model'
import { isValidUUID } from 'src/utils/uuid/uuidUtils'

export async function searchRiderByEmailOrId(searchTerm: string): Promise<Rider[] | Error> {
    if (!isValidUUID(searchTerm)) {
        const searchRes = await searchRider(searchTerm)

        if (searchRes instanceof Error) {
            return searchRes
        } else {
            if (searchRes.data.data.length > 0) {
                return searchRes.data.data
            } else {
                return new Error('Rider not found')
            }
        }
    } else {
        const idRes = await getRider(searchTerm)

        if (idRes instanceof Error) {
            return idRes
        } else {
            return [idRes]
        }
    }
}
